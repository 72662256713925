
















import {Component} from 'vue-property-decorator';
import {readSupplements} from '@/store/crud/getters';
import {dispatchDeleteSupplement, dispatchGetSupplements} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import SupplementsTable from '@/components/tables/SupplementsTable.vue';

@Component({components: {ConfirmationModal, SupplementsTable}})
export default class Supplement extends AppComponent {
  get supplements() {
    const supplements = readSupplements(this.$store);
    if (supplements) {
      return supplements;
    } else {
      return [];
    }
  }
  public fetchingSupplementsData = false;
  /**
   * created hook
   * it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetchs data asyncrhonously from the store / server
   * and displays loading spinner
   */
  public async fetchData() {
    this.fetchingSupplementsData = true;
    await this.$nextTick();
    await dispatchGetSupplements(this.$store);
    this.fetchingSupplementsData = false;
  }

  /**
   * Delete a supplement and refresh the screen
   */
  public async deleteSupplement(supplementId: string) {
    await dispatchDeleteSupplement(this.$store, {supplementId});
    this.fetchData();
  }
}
